import AudioRecorder from 'audio-recorder-polyfill';

let mediaRecorder; // Variable que tendra el grabador del audio
const audioChunks = []; // Array que almacenara las partes del audio que se iran grabando.
const mimeTypeAudio = 'audio/webm'; // Formato a utilizar al generar el audio.
const mimeTypeAudioiOS = 'audio/mp4'; // Formato de iOS a utilizar al generar el audio.
let currentTypeAudio = mimeTypeAudio;
let streamTracks = []; // Partes del grabador del audio, para identificarlos y detenerlos posterior a la grabación.

/**
 * Inicializa el grabador del audio usando el microfono.
 * @returns 
 */
const initRecordAudio = async () => {

    // Pide los permisos para utilizar el microfono del navegador antes de grabar el microfono.
    return await navigator.mediaDevices.getUserMedia({ video: false, audio: true })
    .then( stream => {

        // Verifica si el equipo soporta audio/mp4, puede que sea un iOS y este sea el unico formato que soporta.
        if( MediaRecorder.isTypeSupported( mimeTypeAudioiOS ) )
        {
            // currentTypeAudio = mimeTypeAudioiOS;
            currentTypeAudio = 'audio/wav';

            window.MediaRecorder = AudioRecorder;
        }        
        // Verifica si soporta el formato audio/webm, soportado por los nevagdores más comunes y moviles.
        else if( MediaRecorder.isTypeSupported( mimeTypeAudio ) )
            currentTypeAudio = mimeTypeAudio;


        // Si el usuario da el permiso para utilizar el microfono.

        // Inicializa el grabador del audio.

        // Establece el formato del audio a retornar.
        mediaRecorder = new MediaRecorder( stream, { mimeType: currentTypeAudio } )

        // Evento que se ejecutara mientras graba el audio.
        // Ira almacenando en un array las partes del audio que se vayan grabando.
        mediaRecorder.addEventListener('dataavailable', event => {

            if( event.data.size > 0 )
            audioChunks.push( event.data );

        });

        // Obtiene las partes del grabador del audio.
        streamTracks = stream.getTracks();

        // Retorna un true indicando que se obtuvo el permiso y se inicializo el grabador.
        return true;
    } )
    .catch( error => {
        // Si el usuario no dio el permiso, se retorna un false para inidicar el estado del grabador.

        console.error( 'There was a error while getting the permition', error );

        return false;
    } );

}

/**
 * Inicia a grabar el audio usando el microfono.
 * @returns 
 */
export const startRecordAudio = async () => {

    // Comprueba si el grabador no esta inicializado.
    if( !mediaRecorder )
    {
        // Si no esta inicializado, procese a pedir el permiso del microfo e inicalizar el grabador.
        const resultPemition = await initRecordAudio();
        
        // Si no se pudo inicializar se retorna.
        if( !resultPemition ) return;
    }

    // Inicia la grabación del audio.
    mediaRecorder.start();

    // Retorna una true para indicar que estado si la grabación comenzo.
    return true;
}

/**
 * Detiene la grabación del audio y genera un Blob usando los Chunks del audio grabado.
 * @returns 
 */
export const finishRecordAudio = () => {

    // Verifica si el grabado no esta inicializado.
    if( !mediaRecorder ) return;

    // Retorna el contenido del archivo de audio.
    return new Promise( resolve => {

        mediaRecorder.addEventListener('stop', () => {
            // Junta todas las partes del audio que estan en el array
            // para crear un archivo.
            const audioBlob = new Blob( audioChunks, { type: currentTypeAudio } );
    
            mediaRecorder = null; // Eliminando la herramienta para grabar el audio.
            audioChunks.length = 0; // Vaciando el array que tenia chunks del audio garabado.

            // Las partes del grabador se detienen, para evitar que el navegador siga indicando que aun esta grabando.
            if( streamTracks.length > 0 )
                streamTracks.forEach( item => item.stop() );

            // Retorna el Blo del audio en la promesa.
            resolve( audioBlob )
        });

        // Detiene la grabación del audio.
        mediaRecorder.stop();

    } );
}