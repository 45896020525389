let ttsSpeaker = null;
let voice = null;

export function initTTSNavigator()
{
    if( 'speechSynthesis' in window )
    {
        // Navegador es compatible con el TTS.
        ttsSpeaker = new SpeechSynthesisUtterance();
        voice = window.speechSynthesis.getVoices()[0];
        // window.speechSynthesis.getVoices();
        // debugger;

        // console.info( 'Navigator is commpatible with TTS, voice assigned is', [ voice ] );
    }
    else
    {
        console.info( 'Navigator is not commpatible with TTS' );
    }
}

function setVoice( voiceSelected )
{
    voice = voiceSelected;
}

function getVoice()
{
    return voice;
}

function getAllVoices()
{
    return window.speechSynthesis.getAllVoices();
}

export function addTextAndSpeak( text )
{
    if( !ttsSpeaker ) return;

    ttsSpeaker.text = text; // Asigna el texto para el voice.
    ttsSpeaker.voice = voice; // Asigna el voice a utilizar.
    
    window.speechSynthesis.speak( ttsSpeaker ); // Reproduce el audio del texto ingresado.
}

export function cancelSpeak()
{
    if( !ttsSpeaker ) return;

    window.speechSynthesis.cancel();
}