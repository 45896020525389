const playerTTSVoice = new Audio();
let queueForPlayerTTSVoice = [];

window.addEventListener( 'DOMContentLoaded', () => {
    playerTTSVoice.addEventListener( 'ended', () => {
        playTracksInQueue();
    } );
} );

export function addTrackToQueue( url )
{
    queueForPlayerTTSVoice.push( url );

    if( playerTTSVoice.ended || playerTTSVoice.paused )
    playTracksInQueue();
}

export function playTracksInQueue()
{
    if( queueForPlayerTTSVoice.length < 1 /* || !playerTTSVoice.ended || !playerTTSVoice.paused */ ) return;

    const currentAudioUrl = queueForPlayerTTSVoice.shift();

    playerTTSVoice.pause();

    playerTTSVoice.src = currentAudioUrl;
    playerTTSVoice.load();
    playerTTSVoice.play();

}

export function cancelAllTracksInQueue()
{
    // Limpia el array.
    queueForPlayerTTSVoice.splice( 0, queueForPlayerTTSVoice.length );

    playerTTSVoice.src = "";
    playerTTSVoice.pause();
}